import React, { useState } from 'react'
import { numberToCurrency } from '@/shared/helpers/String'
import AddToCartButton from '@/public/product/AddToCartButton'
import ApplicationButton from '@/public/product/ApplicationButton'
import PolicyLinks from '@/public/product/PolicyLinks'
import PropTypes from 'prop-types'
import UseCart from '@/public/hooks/use-cart'
import PriceBlockMembershipOptions from './PriceBlockMembershipOptions'

export default function PriceBlockWithoutQuantity({
  product,
  returnPolicy,
  shippingPolicy,
  planTerms,
  customerIsMember,
  accessMembershipPrice,
  annualAccessMembershipPrice,
  debug = false,
}) {
  const { cart } = UseCart()
  const cartContainsMembership = cart?.contents?.some((item) => item.data.category === 'membership')
  const [selectedOption, setSelectedOption] = useState(null)

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  return (
    <div className="card">
      <div className="card-content pt-4">
        <div className="subtitle bold fs-4 d-flex flex-column flex-sm-row">
          <div>
            <span>{numberToCurrency(product.price)}</span>
            {(product.category === 'lab' || product.category === 'test') && !customerIsMember && (
              <span className="fw-light fs-6 d-block d-sm-inline">
                <span className="text-secondary"> + </span>
                Access Membership
              </span>
            )}
          </div>
          {(product.category === 'lab' || product.category === 'test') && !customerIsMember && (
            <div className="ms-0 ms-sm-auto">
              <button
                type="button"
                className="btn btn-link d-inline-block align-items-baseline"
                id="access-membership-modal-button"
                data-bs-toggle="modal"
                data-bs-target="#access-membership-modal"
              >
                Why do I need a membership?
              </button>
            </div>
          )}
        </div>

        <div className="actions">
          <hr className="mb-3" />

          {product.is_applicable
            && (
              <ApplicationButton
                product={product}
                button_text={product.button_text}
                product_url={product.application_url}
                target="_blank"
                className="btn btn-primary btn-block"
              />
            )}

          {(product.category === 'lab' || product.category === 'test') && !customerIsMember && !cartContainsMembership && (
            <PriceBlockMembershipOptions
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
              accessMembershipPrice={accessMembershipPrice}
              annualAccessMembershipPrice={annualAccessMembershipPrice}
              product={product}
            />
          )}

          {product.is_purchasable
            && (
              <AddToCartButton
                product={product}
                className="btn btn-primary btn-block"
                membershipOption={selectedOption}
              />
            )}

          <PolicyLinks
            product={product}
            shippingPolicy={shippingPolicy}
            returnPolicy={returnPolicy}
            planTerms={planTerms}
          />
        </div>
      </div>
    </div>
  )
}

PriceBlockWithoutQuantity.propTypes = {
  product: PropTypes.shape({
    price: PropTypes.string,
    is_applicable: PropTypes.bool,
    button_text: PropTypes.string,
    applicationUrl: PropTypes.string,
    is_purchasable: PropTypes.bool,
  }).isRequired,
  returnPolicy: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  shippingPolicy: PropTypes.string,
  planTerms: PropTypes.string,
  customerIsMember: PropTypes.bool,
  accessMembershipPrice: PropTypes.string,
  annualAccessMembershipPrice: PropTypes.string,
}
PriceBlockWithoutQuantity.defaultProps = {
  returnPolicy: null,
  shippingPolicy: null,
  planTerms: null,
  customerIsMember: null,
  accessMembershipPrice: null,
  annualAccessMembershipPrice: null,
}
