import React from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch } from 'react-redux'
import useCart from '@/public/hooks/use-cart'
import { accessToken } from '@/shared/helpers/CSRF'
import {
  setOpen, setLoading, addItemToCart, handleRemove,
} from '@/public/redux/cartSlice'

export default function CartMembershipToggle({
  membership,
  monthlyMembership,
  annualMembership,
  children,
}) {
  const { cart, mutate } = useCart()
  const dispatch = useDispatch()

  const membershipIsAnnual = membership.sku === annualMembership.data.sku

  const toggleCartMemberships = async (membershipToAdd, membershipToRemove) => addItemToCart(
    accessToken,
    cart,
    membershipToAdd,
    1,
    true,
    mutate,
    dispatch,
  )
    .then(() => handleRemove(accessToken, cart, membershipToRemove, mutate, dispatch))
    .finally(() => {
      if (window.location.pathname.includes('/checkout')) {
        dispatch(setOpen(false))
      }
    })

  const handleToggle = async (e) => {
    e.stopPropagation()

    dispatch(setLoading(true))
    dispatch(setOpen(true))

    if (membershipIsAnnual) {
      await toggleCartMemberships(monthlyMembership.data, annualMembership.data)
    } else {
      await toggleCartMemberships(annualMembership.data, monthlyMembership.data)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      handleToggle(e).then(() => {})
    }
  }

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={membershipIsAnnual}
        onChange={(e) => handleToggle(e)}
      />
      <span
        className="slider round"
        role="switch"
        tabIndex="0"
        aria-checked={membershipIsAnnual}
        aria-label="Toggle membership type"
        onKeyDown={handleKeyDown}
      />
      {children}
    </label>
  )
}

CartMembershipToggle.propTypes = {
  membership: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sku: PropTypes.string.isRequired,
  }).isRequired,
  monthlyMembership: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      sku: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  annualMembership: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      sku: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  children: PropTypes.node,
}
CartMembershipToggle.defaultProps = {
  children: null,
}
