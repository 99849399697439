/* eslint-disable no-param-reassign */
// core version + navigation, pagination modules:
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/swiper-bundle.min.css'

$(() => {
  if (!document.querySelector('.swiper')) {
    return
  }
  if (window.innerWidth < 768) {
    if (document.querySelector('.assessment-swiper')) {
      return
    }
    const coverCard = document.querySelector('.product-swiper .swiper-slide:has(> .swiper-product.cover)')
    const mobileCoverCardContainer = document.querySelector('.mobile-only-cover-card-container')
    const swiperWrapper = document.querySelector('.product-swiper .swiper-wrapper')
    if (coverCard && mobileCoverCardContainer && swiperWrapper) {
      swiperWrapper.removeChild(coverCard)
      mobileCoverCardContainer.appendChild(coverCard)
      coverCard.style.maxWidth = 'unset'
    }
  }

  // eslint-disable-next-line no-new
  new Swiper('.swiper', {
    modules: [Navigation, Pagination],
    slidesPerView: 'auto',
    spaceBetween: window.innerWidth < 768 ? 12 : 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })
})
