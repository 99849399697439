import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { accessToken } from '@/shared/helpers/CSRF'
import { PaymentElement } from '@stripe/react-stripe-js'
import { useFormContext } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { validationPatterns } from '@/shared/helpers/Errors'
import AddressFields from '@/public/checkout/AddressFields'

export default function Form({
  loadingCustomerAsync = false,
  customer = null,
  states = [],
  provinces = [],
  countries = [],
  cart,
  setSelectedState,
  accessMembershipTermsUrl,
}) {
  const {
    register, watch, trigger, setValue, formState: { errors },
  } = useFormContext()

  let hasMatchingAddresses = false
  try {
    hasMatchingAddresses = (customer && customer.addresses.billing.single_line.replace(/\s/g, '').toLowerCase() === customer.addresses.shipping.single_line.replace(/\s/g, '').toLowerCase())
  } catch (address_was_null) {}
  const useMultipleAddresses = watch('use_multiple_addresses', (!hasMatchingAddresses || false))
  const selectedShippingState = watch('customer.shipping.state')
  const selectedBillingState = watch('customer.billing.state')
  const selectedState = useMultipleAddresses ? selectedShippingState : selectedBillingState

  const phoneField = register('customer.phone', { required: true, pattern: validationPatterns.phone })
  const emailField = register('customer.email', { required: true, pattern: validationPatterns.email })

  const [emailFound, setEmailFound] = useState(false)

  /**
   * Dynamically clear the shipping address fields
   * if customer unchecks "ship to different address?"
   */
  useEffect(() => {
    if (useMultipleAddresses === false) {
      setValue('customer.shipping.address_line_1', '', { shouldValidate: false, shouldDirty: false })
      setValue('customer.shipping.address_line_2', '', { shouldValidate: false, shouldDirty: false })
      setValue('customer.shipping.city', '', { shouldValidate: false, shouldDirty: false })
      setValue('customer.shipping.state', '', { shouldValidate: false, shouldDirty: false })
      setValue('customer.shipping.zip', '', { shouldValidate: false, shouldDirty: false })
    }
  }, [useMultipleAddresses])

  useEffect(() => {
    setSelectedState(selectedState)
  }, [selectedState])

  /**
    * Prefill field values if we have them
    */
  useEffect(() => {
    setValue('customer.phone', customer?.phone || '')
    setValue('customer.email', customer?.email || '')
  }, [customer])

  async function checkEmail(email) {
    const response = await axios({
      method: 'post',
      url: '/api/orders/check_email',
      headers: {
        'Content-Type': 'application/json',
        Accepts: 'application/json',
        'X-CSRF-Token': accessToken,
      },
      data: { uuid: cart.uuid, email },
    })

    setEmailFound(response?.data?.user_found)
  }

  const paymentElementOptions = {
    fields: { billingDetails: 'never' },
    wallets: 'never',
  }

  const shouldShowProfileFields = cart.contains_a_test || cart.contains_a_lab

  return (
    <div>
      <input type="hidden" value={customer?.stripe_customer_id || ''} {...register('customer.stripe_customer_id')} />

      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="first-name" className={`form-label ${errors?.customer?.first_name ? 'error' : ''}`}>First Name</label>
          {
            // prefill & disable visible input
            Boolean(customer?.first_name) || loadingCustomerAsync ? (
              <>
                <input
                  className="form-control"
                  type="text"
                  value={customer?.first_name || ''}
                  disabled
                />
                <input
                  id="first-name"
                  type="hidden"
                  {...register('customer.first_name', { required: true, value: customer?.first_name })}
                />
              </>
            ) : (
              <input
                id="first-name"
                className={`form-control ${errors?.customer?.first_name ? 'error' : ''}`}
                type="text"
                autoComplete="given-name"
                autoCorrect="off"
                autoFocus
                {...register('customer.first_name', { required: true, value: customer?.first_name })}
              />
            )
          }
        </div>
        <div className="col-6">
          <label htmlFor="last-name" className={`form-label ${errors?.customer?.last_name ? 'error' : ''}`}>Last Name</label>
          {
            // prefill & disable visible input
            Boolean(customer?.last_name) || loadingCustomerAsync ? (
              <>
                <input
                  className="form-control"
                  type="text"
                  value={customer?.last_name || ''}
                  disabled
                />
                <input
                  id="last-name"
                  type="hidden"
                  {...register('customer.last_name', { required: true, value: customer?.last_name })}
                />
              </>
            ) : (
              <input
                id="last-name"
                className={`form-control ${errors?.customer?.last_name ? 'error' : ''}`}
                type="text"
                autoComplete="family-name"
                autoCorrect="off"
                {...register('customer.last_name', { required: true, value: customer?.last_name })}
              />
            )
          }
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="email" className={`form-label ${errors?.customer?.email ? 'error' : ''}`}>Email</label>
          {
            // prefill & disable visible input
            Boolean(customer?.email) || loadingCustomerAsync ? (
              <>
                <input
                  className="form-control"
                  type="email"
                  value={customer?.email || ''}
                  disabled
                />
                <input
                  {...emailField}
                  id="email"
                  type="hidden"
                />
              </>
            ) : (
              <input
                {...emailField}
                id="email"
                className={`form-control ${errors?.customer?.email ? 'error' : ''}`}
                type="email"
                autoComplete="email"
                autoCorrect="off"
                onBlur={(e) => {
                  emailField.onBlur(e)
                  checkEmail(e.target.value)
                  trigger('customer.email')
                }}
              />
            )
          }
        </div>
        <div className="col-6">
          <label htmlFor="phone" className={`form-label ${errors?.customer?.phone ? 'error' : ''}`}>Phone Number</label>
          {
            // prefill & disable visible input
            customer?.phone ? (
              <>
                <InputMask
                  mask="999-999-9999"
                  maskchar={null}
                  alwaysShowMask
                  defaultValue={customer.phone}
                  disabled
                >
                  <input className="form-control" type="tel" />
                </InputMask>
                <input
                  {...phoneField}
                  id="phone"
                  type="hidden"
                />
              </>
            ) : (
              <InputMask
                mask={watch('customer.phone') ? '999-999-9999' : null}
                maskchar={null}
                alwaysShowMask
                defaultValue={watch('customer.phone', customer?.phone || '')}
                onChange={(e) => setTimeout(() => phoneField.onChange(e), 250)}
                onBlur={(e) => { phoneField.onChange(e) }}
                onInput={(e) => { phoneField.onChange(e) }}
              >
                <input
                  ref={phoneField.ref}
                  id="phone"
                  name={phoneField.name}
                  className={`form-control ${errors?.customer?.phone ? 'error' : ''}`}
                  type="tel"
                  autoComplete="tel-national"
                  autoCorrect="off"
                />
              </InputMask>
            )
          }
        </div>
      </div>

      {emailFound && (
        <p className="p-4 pt-2">
          We already have an account for this email.
          Login to checkout faster and view your orders.
          &nbsp;&nbsp;
          <a href="/account/login" className="btn btn-primary" style={{ lineHeight: '10px' }}>Login</a>
        </p>
      )}

      { shouldShowProfileFields && (
        <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="date-of-birth" className="form-label">Date of Birth</label>
            <input
              id="date-of-birth"
              type="date"
              className={`form-control ${errors?.customer?.date_of_birth ? 'error' : ''}`}
              {...register('customer.date_of_birth', { required: true })}
              defaultValue={customer?.date_of_birth}
            />
            {errors?.customer?.date_of_birth && (
              <span className="text-danger">{errors.customer.date_of_birth.message}</span>
            )}
          </div>
          <div className="col-6">
            <label htmlFor="sex" className="form-label">Sex</label>
            <select
              id="sex"
              className={`form-control ${errors?.customer?.sex ? 'error' : ''}`}
              {...register('customer.sex', { required: true })}
              defaultValue={customer?.sex}
            >
              <option value="">Select</option>
              <option value="f">Female</option>
              <option value="m">Male</option>
              <option value="?">Prefer not to say</option>
            </select>
            {errors?.customer?.sex && (
              <span className="text-danger">{errors.customer.sex.message}</span>
            )}
          </div>
        </div>
      )}

      { cart.contains_a_membership && (
        <div className="row mb-3">
          <div className="col-6 mt-4 form-check">
            <input
              id="terms"
              className={`form-check-input ${errors?.customer?.first_name ? 'error' : ''}`}
              type="checkbox"
              {...register('customer.terms_agreement', { required: 'Please agree to the terms' })}
            />
            <label htmlFor="terms" className="form-check-label">
              I agree to the &nbsp;
              <a href={accessMembershipTermsUrl} target="_blank" rel="noreferrer">
                Root Access Membership Terms of Service & Membership Agreement (PDF)
              </a>
            </label>
            {errors?.customer?.terms_agreement && (
              <span className="text-danger">{errors.customer.terms_agreement.message}</span>
            )}
          </div>
        </div>
      )}

      <AddressFields customer={customer} states={states} provinces={provinces} countries={countries} type="billing" hasMultiple={useMultipleAddresses} />

      { useMultipleAddresses
            && <AddressFields customer={customer} states={states} provinces={provinces} countries={countries} type="shipping" hasMultiple={useMultipleAddresses} />}

      <div className="row mt-5 mb-3">
        <div className="col-12">
          <div className="form-check">
            <input
              id="use_multiple_addresses"
              className="form-check-input"
              type="checkbox"
              defaultChecked={!hasMatchingAddresses}
              {...register('use_multiple_addresses')}
            />
            <label htmlFor="use_multiple_addresses" className="form-check-label">
              Ship to a different address?
            </label>
          </div>
        </div>
      </div>
      <hr className="line my-5" />

      <div className="row mb-3">
        <div className="col">
          <PaymentElement options={paymentElementOptions} />
        </div>
      </div>
    </div>
  )
}
