import { useDispatch, useSelector } from 'react-redux'
import useCart from '@/public/hooks/use-cart'
import { accessToken } from '@/shared/helpers/CSRF'
import { createCart, addItemToCart } from '@/public/redux/cartSlice'
import PropTypes from 'prop-types'
import { selectVerified } from '../redux/recaptchaSlice'

export default function AutoAddToCart({ product, subscription = false }) {
  const dispatch = useDispatch()
  const isVerified = useSelector(selectVerified)
  const { cart, isLoading, mutate } = useCart()

  if (isLoading || !isVerified) {
    return null
  }

  const handleRedirect = (uuid) => {
    window.location.assign(`/shop-root/checkout/${uuid}`)
  }

  const handleCart = async () => {
    if (cart) {
      const existingItem = cart.contents?.find((item) => item.data.sku === product.sku)

      if (!existingItem) {
        await addItemToCart(accessToken, cart, product, 1, subscription, mutate, dispatch)
      }

      handleRedirect(cart.uuid)
    } else {
      await createCart(accessToken, product, 1, subscription, mutate, dispatch)
    }
  }

  handleCart().then(() => null)

  return null
}

AutoAddToCart.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  subscription: PropTypes.bool.isRequired,
}
