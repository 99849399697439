import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { numberToCurrency } from '@/shared/helpers/String'
import { setQuantity, selectQuantity } from '@/public/redux/productSlice'
import QuantityInput from '@/public/product/QuantityInput'
import AddToCartButton from '@/public/product/AddToCartButton'
import PolicyLinks from '@/public/product/PolicyLinks'
import PropTypes from 'prop-types'
import UseCart from '@/public/hooks/use-cart'
import PriceBlockMembershipOptions from './PriceBlockMembershipOptions'
import NotifyWhenAvailable from './NotifyWhenAvailable'

export default function PriceBlockWithQuantity({
  product,
  returnPolicy,
  shippingPolicy,
  customerIsMember,
  awaitedProductIds,
  accessMembershipPrice,
  annualAccessMembershipPrice,
}) {
  const dispatch = useDispatch()
  const quantity = useSelector(selectQuantity)
  const backOrdered = /back_ordered/i.test(product.status)

  const { cart } = UseCart()
  const cartContainsMembership = cart?.contents?.some((item) => item.data.category === 'membership')
  const [selectedOption, setSelectedOption] = useState(null)

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  return (
    <div className="card">
      <div className="card-content pt-4">
        {
          !backOrdered && (
            <>
              <p className="subtitle secondary-d50">Quantity</p>

              <QuantityInput
                quantity={quantity}
                handleChange={(newQuantity) => dispatch(setQuantity(newQuantity))}
              />

              <hr />
            </>
          )
        }

        <div className="subtitle bold d-flex flex-column flex-sm-row">
          {
            backOrdered ? (
              <>
                <del>{numberToCurrency(product.price)}</del>
                <span className="text-opacity-50 ms-3 text-secondary small fw-normal">Sold Out</span>
              </>
            ) : (
              <>
                <div>
                  <span>{numberToCurrency(product.price)}</span>
                  {(product.category === 'lab' || product.category === 'test') && !customerIsMember && (
                    <span className="fw-light fs-6 d-block d-sm-inline">
                      <span className="text-secondary"> + </span>
                      Access Membership
                    </span>
                  )}
                </div>
                {(product.category === 'lab' || product.category === 'test') && !customerIsMember && (
                  <div className="ms-0 ms-sm-auto">
                    <button
                      type="button"
                      className="btn btn-link d-inline-block align-items-baseline"
                      id="access-membership-modal-button"
                      data-bs-toggle="modal"
                      data-bs-target="#access-membership-modal"
                    >
                      Why do I need a membership?
                    </button>
                  </div>
                )}
              </>
            )
          }
        </div>

        <div className="actions mt-0">
          {
            backOrdered ? (
              <NotifyWhenAvailable product={product} awaitedProductIds={awaitedProductIds} wide />
            ) : (
              <>
                <hr className="mb-2" />

                {(product.category === 'lab' || product.category === 'test') && !customerIsMember && !cartContainsMembership && (
                  <PriceBlockMembershipOptions
                    selectedOption={selectedOption}
                    handleOptionChange={handleOptionChange}
                    accessMembershipPrice={accessMembershipPrice}
                    annualAccessMembershipPrice={annualAccessMembershipPrice}
                    product={product}
                  />
                )}

                <AddToCartButton
                  product={product}
                  quantity={quantity}
                  className="btn btn-primary btn-block"
                  membershipOption={selectedOption}
                />

                {product.is_subscribable && customerIsMember
                  && (
                    <AddToCartButton
                      product={product}
                      className="btn btn-subscription btn-block mt-3"
                      subscription
                      membershipOption={selectedOption}
                    >
                      Subscribe
                    </AddToCartButton>
                  )}

                {product.is_subscribable && !customerIsMember
                  && (
                    <AddToCartButton
                      product={product}
                      className="btn btn-subscription btn-block mt-3"
                      subscription
                      membershipOption={selectedOption}
                    >
                      Subscribe & Save
                      {' '}
                      {product.calculated_subscription_savings_percentage}
                    </AddToCartButton>
                  )}
              </>
            )
          }

          <PolicyLinks
            product={product}
            shippingPolicy={shippingPolicy}
            returnPolicy={returnPolicy}
          />
        </div>
      </div>
    </div>
  )
}

PriceBlockWithQuantity.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    price: PropTypes.string,
    is_subscribable: PropTypes.bool,
    calculated_subscription_savings_percentage: PropTypes.string,
  }).isRequired,
  returnPolicy: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  shippingPolicy: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  customerIsMember: PropTypes.bool,
  awaitedProductIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  accessMembershipPrice: PropTypes.string,
  annualAccessMembershipPrice: PropTypes.string,
}
PriceBlockWithQuantity.defaultProps = {
  returnPolicy: null,
  shippingPolicy: null,
  customerIsMember: null,
  accessMembershipPrice: null,
  annualAccessMembershipPrice: null,
}
