import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useCart from '@/public/hooks/use-cart'
import { accessToken } from '@/shared/helpers/CSRF'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import {
  createCart, addItemToCart, incrementCart, setOpen, setLoading,
} from '@/public/redux/cartSlice'

export default function AddToCartButton({
  product,
  quantity = 1,
  text = 'Add to Cart',
  className = 'btn btn-primary',
  disabled = false,
  subscription = false,
  children,
  alwaysEnabled = false,
  membershipOption = '',
}) {
  const verified = useSelector(selectVerified)
  const { cart, mutate } = useCart()
  const dispatch = useDispatch()

  const existingItem = cart?.contents?.find((item) => item.data.sku === product.sku)
  const otherPurchaseTypeSelected = existingItem && existingItem.subscription !== subscription
  const quantityLimited = existingItem && !product.quantifiable

  const shouldDisable = alwaysEnabled === false
    && (!verified
    || quantityLimited
    || disabled
    || /back_ordered/i.test(product.status)
    || otherPurchaseTypeSelected)

  const [buttonText, setButtonText] = useState(text)
  useEffect(() => {
    if (existingItem && shouldDisable) {
      setButtonText('Currently In Cart')
    } else {
      setButtonText(text)
    }
  }, [existingItem, shouldDisable, text])

  const handleClick = (e) => {
    e.stopPropagation()

    dispatch(setLoading(true))
    dispatch(setOpen(true))

    if (existingItem) {
      incrementCart(accessToken, cart, product, existingItem, mutate, dispatch)
    } else if (cart) {
      addItemToCart(
        accessToken,
        cart,
        product,
        quantity,
        subscription,
        mutate,
        dispatch,
        membershipOption,
      )
    } else {
      createCart(accessToken, product, quantity, subscription, mutate, dispatch, membershipOption)
    }
  }

  return (
    <button
      type="button"
      className={className}
      disabled={shouldDisable}
      onClick={(e) => handleClick(e)}
    >
      { children || buttonText }
    </button>
  )
}
