import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useStripe } from '@stripe/react-stripe-js'
import useCart from '@/public/hooks/use-cart';
import { selectVerified } from '@/public/redux/recaptchaSlice'
import {
  selectError,
  selectLoading,
} from '@/public/redux/cartSlice'
import CartItem from '@/public/cart/CartItem'
import CartLedger from '@/public/cart/CartLedger'
import ModalButton from '@/public/ModalButton'
import LoadingOverlay from '@/public/LoadingOverlay'
import DiscountCode from '@/public/cart/DiscountCode'

export default function Cart({
  terms_policy_modal_id,
  activeDiscountCodePresent,
  selectedState,
  monthlyMembership,
  annualMembership,
}) {
  const { register, formState: { errors } } = useFormContext()

  const stripe = useStripe()
  const verified = useSelector(selectVerified)
  const paymentError = useSelector(selectError)
  const loading = useSelector(selectLoading)
  const { cart } = useCart()

  const restricted_cart_items = cart?.contents?.filter((item) => (
    item.data.state_sales_restrictions?.includes(selectedState)
  ))
  const cartContainsLabOrTest = cart?.contents?.some((cartItem) => cartItem.data.category === 'lab' || cartItem.data.category === 'test')

  return (
    <div className="col-lg-6 col-xl-5 cart-col">
      <h2 className="underline mb-5">Cart</h2>

      <div className="cart-pane cart-checkout loading-overlay--container">
        <LoadingOverlay show={loading} className="tan" />

        <div className="cart-list pe-0">
          { cart?.contents?.map((item, index) => (
            <CartItem
              key={`cart-item-${index}`}
              data={item.data}
              quantity={item.quantity}
              quantifiable={item.quantifiable}
              subscription={item.subscription}
              pickupOrDelivery={item?.pickup_or_delivery}
              cartContainsLabOrTest={cartContainsLabOrTest}
              monthlyMembership={monthlyMembership}
              annualMembership={annualMembership}
            />
          ))}
        </div>

        <CartLedger />

        {activeDiscountCodePresent && <DiscountCode cart={cart} />}
      </div>

      <div className="cart-footer pe-0">
        {restricted_cart_items.length > 0 && (
          <div className="text-danger">
            <hr />
            <h6 className="text-danger">Oh no!</h6>
            <p>
              We're very sorry, but you have
              {restricted_cart_items.length == 1 ? ' an item' : ' items'}
              {' '}
              in your cart that we're not allowed to sell to you due to sales restrictions in the state to which you're shipping it.
            </p>
            <p>
              Please remove the following
              {restricted_cart_items.length == 1 ? ' item' : ' items'}
              {' '}
              to continue checking out:
            </p>
            <ul>{ restricted_cart_items.map((item) => <li key={item.data.name}>{item.data.name}</li>) }</ul>
          </div>
        )}
        <p>
          By clicking pay you agree to be charged and accept our&nbsp;
          <ModalButton
            id={terms_policy_modal_id}
            className="btn btn-link"
            title="Click to view our terms and conditions"
            text="terms and conditions*"
          />
        </p>

        <div className="cart-footer-actions">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={!!(!verified || loading || !stripe || restricted_cart_items.length > 0)}
          >
            {cart.contains_subscription ? 'Continue Checkout' : 'Place Order'}
          </button>
        </div>

        { paymentError
        && <p className="text-danger text-center mt-5 mb-n5 px-5">{paymentError}</p>}
      </div>
    </div>
  )
}
