import React from 'react'
import PropTypes from 'prop-types'
import { numberToCurrency } from '../../shared/helpers/String'

export default function PriceBlockMembershipOptions({
  selectedOption,
  handleOptionChange,
  accessMembershipPrice,
  annualAccessMembershipPrice,
  product,
}) {
  return (
    <>
      <div className="d-flex membership-options-heading flex-column flex-sm-row">
        <div className="order-2 order-sm-1 my-2 my-sm-0">
          <span>Select your membership payment plan</span>
        </div>
        <div className="ms-sm-auto order-1 order-sm-2">
          <div className="d-flex align-items-center">
            <span className="me-1">Already a member?</span>
            <a href={`/account/login/?redirect_url=${product.permalink}`} className="btn btn-link p-0">Login</a>
          </div>
        </div>
      </div>
      <div className="membership-options mt-1 mb-3">
        <div className="form-check mb-2">
          <label className="d-block w-100">
            <div className={`card border border-secondary border-2 rounded-3 ${selectedOption === 'monthly' ? 'border-checked' : ''}`}>
              <div className="card-body d-flex">
                <input className="form-check-input border-secondary" type="radio" name="membershipOptions" value="monthly" onChange={handleOptionChange} />
                <span className="ms-2">
                  Monthly Membership -
                  {' '}
                  {numberToCurrency(accessMembershipPrice)}
                </span>
              </div>
            </div>
          </label>
        </div>
        <div className="form-check mb-2">
          <label className="d-block w-100">
            <div className={`card border border-secondary border-2 rounded-3 ${selectedOption === 'annual' ? 'border-checked' : ''}`}>
              <div className="card-body d-flex flex-column">
                <div className="d-sm-flex align-items-center">
                  <div className="d-flex">
                    <input className="form-check-input border-secondary" type="radio" name="membershipOptions" value="annual" onChange={handleOptionChange} />
                    <div className="ms-2">
                      <div>
                        Annual Membership -
                        {' '}
                        {numberToCurrency(annualAccessMembershipPrice)}
                      </div>
                      <div className="d-sm-none membership-savings">
                        Save over $
                        {Math.floor((accessMembershipPrice * 12) - annualAccessMembershipPrice)}
                        {' '}
                        for the year!
                      </div>
                    </div>
                  </div>
                  <span className="ms-sm-auto mt-2 mt-sm-0 d-none d-sm-block membership-savings">
                    Save over $
                    {Math.floor((accessMembershipPrice * 12) - annualAccessMembershipPrice)}
                    {' '}
                    for the year!
                  </span>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </>
  )
}

PriceBlockMembershipOptions.propTypes = {
  selectedOption: PropTypes.string,
  handleOptionChange: PropTypes.func.isRequired,
  accessMembershipPrice: PropTypes.string.isRequired,
  annualAccessMembershipPrice: PropTypes.string.isRequired,
  product: PropTypes.shape({
    permalink: PropTypes.string,
  }).isRequired,
}

PriceBlockMembershipOptions.defaultProps = {
  selectedOption: null,
}
