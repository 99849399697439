import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useCart from '@/public/hooks/use-cart'
import { accessToken } from '@/shared/helpers/CSRF'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import {
  setOpen, setLoading, createCart, addItemToCart,
} from '@/public/redux/cartSlice'

export default function JoinButton({
  product,
  text = 'Join Root Access',
  className = 'btn btn-primary',
  disabled = false,
  subscription = true,
  children = null,
  hidden = false,
}) {
  const verified = useSelector(selectVerified)
  const shouldDisable = !verified || disabled

  const { cart, mutate } = useCart()
  const dispatch = useDispatch()

  const existingItem = cart?.contents?.find((item) => item.data.sku === product.sku)

  const handleClick = (e) => {
    e.stopPropagation()

    dispatch(setLoading(true))
    dispatch(setOpen(true))

    if (cart) {
      if (!existingItem) {
        addItemToCart(accessToken, cart, product, 1, subscription, mutate, dispatch)
      } else {
        dispatch(setLoading(false))
      }
    } else {
      createCart(accessToken, product, 1, subscription, mutate, dispatch)
    }
  }

  return hidden ? null : (
    <button
      type="button"
      className={className}
      disabled={shouldDisable}
      onClick={(e) => handleClick(e)}
    >
      { children || text }
    </button>
  )
}
