import React, { useRef } from 'react'
import uuid from 'react-uuid'
import { useDispatch, useSelector } from 'react-redux'
import { addComponent } from '@/admin/components/redux/componentsSlice'
import { titleize } from '@/shared/helpers/String'

export default function Modal({ availableComponents }) {
  const dispatch = useDispatch()
  const closeRef = useRef()

  const handleSelection = (component) => {
    closeRef.current.click()
    dispatch(addComponent(component))
  }

  return availableComponents.length > 0 && (
  <>
    <button
      type="button"
      className="btn btn-secondary"
      data-bs-toggle="modal"
      data-bs-target="#components-modal"
    >
      Add Component
    </button>

    <div
      id="components-modal"
      className="modal fade"
      tabIndex="-1"
      aria-labelledby="components-modal-label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-md-down modal-xl" style={{ height: 'auto' }}>
        <div className="modal-content">

          <div className="modal-header">
            <h4 className="modal-title text-uppercase" id="components-modal-label">
              Select A Component
              <span className="ms-3 fs-tiny text-muted">(scroll for more)</span>
            </h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>

          <div className="modal-body">
            <div className="container-fluid components">

              {
                availableComponents.map((component, index) => (
                  <div className={`row py-2 ${index === 0 ? '' : 'mt-2'}`} key={uuid()}>
                    <div className="col-3 cursor-pointer" onClick={() => handleSelection(component)}>
                      { component.image
                        ? <img src={component.image} alt={`An example of ${titleize(component.component)}`} className="w-100 border rounded" />
                        : <div className="w-100 text-muted text-center d-inline-block align-middle py-4 px-6 fs-6 border rounded bg-light">N/A</div>}
                    </div>
                    <div className="col-7">
                      <strong className="fs-6">{ titleize(component.component) }</strong>
                      <br />
                      { component.description }
                    </div>
                    <div className="col-2 d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary m-0 w-100 fs-6 p-2"
                        onClick={() => handleSelection(component)}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                ))
              }

            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              ref={closeRef}
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>

        </div>
      </div>
    </div>
  </>
  )
}
