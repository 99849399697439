import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCart from '@/public/hooks/use-cart';
import { setOpen, selectOpen, selectLoading } from '@/public/redux/cartSlice'
import { numberToCurrency } from '@/shared/helpers/String'
import LoadingOverlay from '@/public/LoadingOverlay'
import CartItem from '@/public/cart/CartItem'
import CartLedger from '@/public/cart/CartLedger'

export default function CartDrawer({
  checkoutUrl,
  customerIsMember,
  monthlyMembership,
  annualMembership,
  debug = false,
}) {
  const { cart, isError } = useCart()

  const drawer = useRef(null)

  const dispatch = useDispatch()
  const open = useSelector(selectOpen)
  const loading = useSelector(selectLoading)
  const fullCheckoutUrl = `${checkoutUrl}/${cart?.uuid}`

  const cartContainsLabOrTest = cart?.contents?.some((cartItem) => cartItem.data.category === 'lab' || cartItem.data.category === 'test')

  const handleClose = () => {
    dispatch(setOpen(false))
  }

  const handleClickOutside = (e) => {
    if (
      drawer.current
      && drawer.current.classList.contains('open')
      && drawer.current.contains(e.target) === false
    ) {
      handleClose()
    }
  }

  const preloadCheckout = () => {
    window.__preloader = window.setTimeout(() => {
      if (cart?.uuid && cart.uuid !== '') {
        const prefetch = document.createElement('link')
        prefetch.setAttribute('rel', 'prefetch')
        prefetch.setAttribute('href', fullCheckoutUrl)
        document.head.appendChild(prefetch)

        const prerender = document.createElement('link')
        prefetch.setAttribute('rel', 'prerender')
        prefetch.setAttribute('href', fullCheckoutUrl)
        document.head.appendChild(prerender)
      }
    }, 400)
  }

  const cancelPreloadTimer = () => {
    window.clearTimeout(window.__preloader)
  }

  /**
   * Mount/Unmount
   */
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  return (
    <div ref={drawer} className={`cart-pane cart-drawer ${open ? 'open' : ''}`} role="dialog" aria-modal="true">
      <LoadingOverlay show={loading} className="tan" />

      <div className="cart-header">
        <div className="h2 underline">Cart</div>
        <button className="btn cart-close p-0" type="button" onClick={handleClose}>
          <i className="icon-times" />
        </button>
      </div>

      <div className="cart-list">
        { cart?.contents?.map((item, index) => (
          <CartItem
            key={`cart-item-${index}`}
            data={item.data}
            quantity={item.quantity}
            quantifiable={item.quantifiable}
            subscription={item.subscription}
            pickupOrDelivery={item?.pickup_or_delivery}
            customerIsMember={customerIsMember}
            cartContainsLabOrTest={cartContainsLabOrTest}
            monthlyMembership={monthlyMembership}
            annualMembership={annualMembership}
          />
        ))}

        {isError ? <div className="text-danger">There was an error loading your cart.</div> : <CartLedger /> }
      </div>

      <div className="cart-footer">
        {/* <div className="cart-footer-actions"> */}
        <div className="row">
          <div className="mb-3 mb-md-0 col-sm-6">
            <button type="button" className="btn btn-secondary btn-block" onClick={handleClose}>
              Continue Shopping
            </button>
          </div>
          {!isError && (
            <div className="mb-3 mb-md-0 col-sm-6">
              <a
                href={fullCheckoutUrl}
                className="btn btn-primary btn-block"
                onMouseEnter={preloadCheckout}
                onMouseLeave={cancelPreloadTimer}
              >
                Checkout &mdash;
                {' '}
                {numberToCurrency(cart?.calculated_total_after_savings)}
              </a>
            </div>
          )}
        </div>

        {/* </div> */}
      </div>
    </div>
  )
}
