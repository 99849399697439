import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import axios from 'axios'
import useCart from '@/public/hooks/use-cart'
import { accessToken } from '@/shared/helpers/CSRF'
import { useForm, FormProvider } from 'react-hook-form'
import Form from '@/public/checkout/Form'
import Cart from '@/public/checkout/Cart'
import ExpressCheckout from '@/public/checkout/ExpressCheckout'

import {
  fetchCustomer,
  setCustomer,
  setError,
  setShippingCosts,
  selectCustomer,
  setLoading,
} from '@/public/redux/cartSlice'
import Modal from '@/public/Modal'

// ------------------------------------------------------------------------------
// CHECKOUT FLOW
// ------------------------------------------------------------------------------
// react-hook-form validates user info and shipping fields before form is submitted
//
// The form is then submitted to /api/orders which creates:
//   1. stripe customer
//   2. stripe payment intent
//   3. order with unpaid status
//
// stripe.confirmPayment is called with data from the api call response above
//
// A webhook is received and processed in order.rb after_payment_intent_scceeded!,
// order is updated to paid status.
// ------------------------------------------------------------------------------

export default function Checkout({
  knownCustomer,
  states,
  provinces,
  countries,
  store_default_path,
  confirmation_path,
  cc_logos_url,
  terms_policy_modal_id,
  shipping_costs,
  debug = false,
  activeDiscountCodePresent,
  accessMembershipTermsUrl,
  monthlyMembership,
  annualMembership,
}) {
  const formRef = useRef()
  const formMethods = useForm()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const customer = useSelector(selectCustomer)
  const [cardError, setCardError] = useState(null)
  const [subscriptionConfirmed, setSubscriptionConfirmed] = useState(false)
  const { cart, isLoading } = useCart()
  const [selectedState, setSelectedState] = useState('')

  if (!window.location.pathname.includes(localStorage.getItem('cart'))) {
    window.location = store_default_path
  }

  /**
   * Mount/Unmount
   */
  useEffect(() => {
    if (!knownCustomer && cart?.user_id) {
      dispatch(fetchCustomer(cart?.user_id))
    } else {
      dispatch(setCustomer(knownCustomer))
    }

    dispatch(setShippingCosts(shipping_costs))
  }, [cart?.user_id])

  const handleSubmit = async () => {
    const customerForm = formMethods.getValues('customer')

    submitOrder(customerForm)
  }

  const submitOrder = async (customerForm) => {
    if (debug && console) { console.log('Order submitted with data:', customerForm) }
    dispatch(setLoading(true))

    const { error: submitError } = await elements.submit()
    if (submitError) {
      if (debug && console) { console.log(submitError) }
      dispatch(setError(submitError.message))
      dispatch(setLoading(false))
      return
    }

    try {
      const { data: { order, payment_intent, stripe_customer } } = await axios({
        method: 'post',
        url: '/api/orders',
        headers: { 'X-CSRF-Token': accessToken },
        data: {
          customer: {
            ...customerForm,
          },
          order: {
            cart_id: cart.uuid,
            order_id: cart.order_confirmation_id,
          },
        },
      })

      // No payment intent for free orders
      if (payment_intent) {
        const { error: confirmError } = await stripe.confirmPayment({
          elements,
          clientSecret: payment_intent.client_secret,
          confirmParams: {
            shipping: stripe_customer.shipping,
            payment_method_data: {
              billing_details: {
                name: stripe_customer.name,
                email: stripe_customer.email,
                phone: stripe_customer.phone,
                address: stripe_customer.address,
              },
            },
            return_url: `${window.location.origin}${confirmation_path}/${order.id}`,
          },
        })

        if (confirmError) {
          if (debug && console) { console.log(confirmError) }
          dispatch(setError(confirmError.message))
          dispatch(setLoading(false))
        }
      } else {
        window.location = `${confirmation_path}/${order.id}`
      }
    } catch (orderError) {
      if (debug && console) { console.log(orderError?.response || orderError) }

      if (orderError?.response?.status === 404) {
        window.location = `${store_default_path}/supplements?cart_flash_notice=true`
      }

      dispatch(setError(orderError?.response?.data?.message))
      dispatch(setLoading(false))
    }
  }

  if (isLoading) { return null }
  if (!cart) { window.location = store_default_path }

  return (
    <FormProvider {...formMethods}>
      <section className="pt-4 pt-xl-5 mt-2 mt-lg-4 mt-xl-5">
        <div className="container">
          <form
            id="stripe-form"
            ref={formRef}
            onSubmit={formMethods.handleSubmit(handleSubmit, () => console.log(formMethods.getValues(), formMethods.formState.errors))}
          >
            <input type="hidden" value={cart.uuid} {...formMethods.register('order.cart_uuid')} />

            <div className="row justify-content-between">
              <div className="col-lg-6 col-xl-5 checkout-col">
                <h2 className="underline mb-5">Checkout</h2>
                <ExpressCheckout
                  cart={cart}
                  submitOrder={submitOrder}
                  contents={cart?.contents}
                  terms_policy_modal_id={terms_policy_modal_id}
                />
                <Form
                  setSelectedState={setSelectedState}
                  loadingCustomerAsync={!knownCustomer && cart.user_id}
                  customer={customer}
                  states={states}
                  provinces={provinces}
                  countries={countries}
                  cc_logos_url={cc_logos_url}
                  cardError={cardError}
                  formMethods={formMethods}
                  cart={cart}
                  accessMembershipTermsUrl={accessMembershipTermsUrl}
                />
              </div>

              <Cart
                selectedState={selectedState}
                terms_policy_modal_id={terms_policy_modal_id}
                activeDiscountCodePresent={activeDiscountCodePresent}
                monthlyMembership={monthlyMembership}
                annualMembership={annualMembership}
              />
            </div>
          </form>
        </div>
      </section>
    </FormProvider>
  )
}
