import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { accessToken } from '@/shared/helpers/CSRF'
import { numberToCurrency } from '../../shared/helpers/String'
import AddToCartButton from './AddToCartButton'

function Recommendation({
  recommendation: {
    id, available_on: availableOn, product, perpetual,
  },
  removeRecommendation,
  showMemberPricing,
}) {
  const current = new Date() > new Date(availableOn) || perpetual
  const availableOnMessage = current ? 'Available' : 'Recommended Order Date'
  const recommendedMessage = 'Recommended On'
  const availability = current ? 'success' : 'warning'

  function handleDecline() {
    axios({
      method: 'patch',
      url: `/api/product_recommendations/${id}/decline`,
      headers: { 'X-CSRF-Token': accessToken },
    })
      .then(() => removeRecommendation(id))
  }

  return (
    <div className="mb-4 d-flex align-items-center justify-content-between">
      <div>
        <div>
          {product.service_category ? (
            <span
              className="me-2"
              data-tooltip-id="service-category-tooltip"
              data-tooltip-content={product.service_category?.note || ''}
            >
              {product.name}
              &nbsp;
              <span className="info-icon">&#9432;</span>
            </span>
          ) : (
            <span className="me-2">{product.name}</span>
          )}
          {showMemberPricing ? (
            <>
              <span className="text-decoration-line-through mx-2">{numberToCurrency(product.price)}</span>
              &nbsp;
              <strong>
                {numberToCurrency(parseFloat(product.price) * 0.85)}
                {' '}
                member price
              </strong>
            </>
          ) : (
            <strong>{numberToCurrency(product.price)}</strong>
          )}
        </div>
        <small className={`text-${availability} mt-1`}>
          {perpetual ? recommendedMessage : availableOnMessage}
          {!current || perpetual ? (
            <>
              {' - '}
              {availableOn}
            </>
          ) : null }
        </small>
      </div>
      <div id={`product-button-${id}`}>
        <AddToCartButton product={product} />
        {product.is_subscribable
          && (
            <AddToCartButton product={product} className="btn btn-subscription ms-lg-3" subscription>
              Subscribe
            </AddToCartButton>
          )}
        {perpetual === false && (
          <button type="button" className="btn btn-secondary ms-2" onClick={handleDecline}>Decline</button>
        )}
      </div>
      <Tooltip id="service-category-tooltip" />
    </div>
  )
}

export default function Recommendations({ recommendations, title, showMemberPricing }) {
  const [recs, setRecs] = useState(recommendations)

  const removeRecommendation = (id) => {
    setRecs(recs.filter((rec) => rec.id !== id))
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4>{title}</h4>
      </div>
      <div className="card-content">
        {recs.length === 0 && (
          <div>No recommendations yet</div>
        )}
        {recs.map((recommendation) => (
          <Recommendation
            key={recommendation.id}
            recommendation={recommendation}
            removeRecommendation={removeRecommendation}
            showMemberPricing={showMemberPricing}
          />
        ))}
      </div>
    </div>
  )
}

Recommendation.propTypes = {
  recommendation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    perpetual: PropTypes.bool.isRequired,
    product: PropTypes.shape({
      price: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      is_subscribable: PropTypes.bool.isRequired,
      quantifiable: PropTypes.bool.isRequired,
      service_category: PropTypes.shape({
        note: PropTypes.string,
      }),
    }),
    available_on: PropTypes.string.isRequired,
  }).isRequired,
  removeRecommendation: PropTypes.func.isRequired,
  showMemberPricing: PropTypes.bool,
}

Recommendation.defaultProps = {
  showMemberPricing: false,
}

Recommendations.propTypes = {
  recommendations: PropTypes.arrayOf(PropTypes.shape).isRequired,
  title: PropTypes.string.isRequired,
  showMemberPricing: PropTypes.bool,
}

Recommendations.defaultProps = {
  showMemberPricing: false,
}
