import useSWR from 'swr'
import axios from 'axios'

export default function useCart() {
  const fetcher = () => {
    const uuid = window.localStorage.getItem('cart')
    if (!uuid) { return null }

    return axios.get(`/api/cart/${uuid}`)
      .then((response) => response.data.cart)
      .catch((error) => {
        if (error.response.status === 404) {
          window.localStorage.removeItem('cart')
        }
        throw error
      })
  }

  const {
    data: cart, isLoading, isError, mutate,
  } = useSWR('cart', fetcher)

  return {
    cart, isLoading, isError, mutate,
  }
}
